import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import InvoiceInfo from 'components/Core/Invoice/Info/InvoiceInfo';
import ViewInvoiceMessage from 'components/Core/Invoice/ViewInvoiceMessage/ViewInvoiceMessage';
import InvoiceViewer from 'components/Core/Invoice/Viewer/InvoiceViewer';
import PaymentInfo from 'components/Core/Payment/PaymentInfo/PaymentInfo';
import { Download, Print } from 'components/Shared/Icons/Icons';
import PreviewCard from 'components/Shared/PreviewCard/PreviewCard';
import ProgressButton from 'components/Shared/ProgressButton/ProgressButton';
import { LineItems } from 'pages/subscription.page';
import SegmentIO from 'reporting/SegmentIO';
import { fetchPDFDocument } from 'store/invoiceDocument/slice';
import { isInvoiceLineItemsEnabled } from 'store/ixp/selector';
import { colors } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { InvoiceDocument } from 'types/InvoiceDocument';
import { TXN_MAP } from 'types/constants';

export type Props = {
  companyName: string;
  companyLogoUrl?: string;
  number?: string;
  dueDate?: string;
  amount: number;
  balanceAmount: number;
  currency?: string;
  showLogo?: boolean | string;
  token: string;
  isPartiallyPaid?: boolean;
  isPayable?: boolean;
  isFullyPaid?: boolean;
  invoiceDocument: InvoiceDocument;
  downloadPDFDocument: (...args: any[]) => any;
  showModal: (...args: any[]) => any;
  fetchPDFDocument: (...args: any[]) => any;
  lazyFetch?: boolean;
  lineItems?: LineItems;
  taxAmount?: number;
  featureFlags?: FeatureFlags;
  ixp?: IXP;
  gratuityValue?: number;
  totalAmount?: number;
  feeAmount?: number;
};

type InvoicePreviewCardState = {
  invoiceDownloadViewOrPrintClicked: boolean;
};

class InvoicePreviewCard extends Component<Props, InvoicePreviewCardState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invoiceDownloadViewOrPrintClicked: false,
    };
  }
  render() {
    const {
      companyName,
      companyLogoUrl,
      number,
      dueDate,
      balanceAmount,
      currency,
      token,
      isPartiallyPaid = false,
      isPayable,
      gratuityValue = 0,
      isFullyPaid,
      invoiceDocument: { fetchPDFStatus },
      totalAmount,
      lazyFetch = false,
      fetchPDFDocument,
      showModal,
      featureFlags,
      ixp,
      lineItems,
      taxAmount,
      feeAmount,
      amount,
    } = this.props;

    const shouldShowInvoiceLineItems =
      featureFlags &&
      ixp &&
      isInvoiceLineItemsEnabled({
        ixp,
        featureFlags,
      });

    const notFetched = this.props.invoiceDocument.fetchPDFStatus === null && this.props.lazyFetch;
    const lazyFetchPDF = () => {
      //If we're on lazyFetch test and the PDF wasn't fetched before
      if (notFetched) {
        return fetchPDFDocument(token);
      }
      return true;
    };
    const onClickDownload = () => {
      lazyFetchPDF();
      this.setState({ invoiceDownloadViewOrPrintClicked: true });
      this.props.downloadPDFDocument(lazyFetch);
      SegmentIO.transactionEngaged({
        activity_type: 'optional_actions',
        ui_action: 'clicked',
        ui_object: 'download_invoice_pdf',
        ui_object_detail: 'button',
        ui_access_point: 'transaction_flow',
      });
    };
    const onClickView = () => {
      lazyFetchPDF();
      this.setState({ invoiceDownloadViewOrPrintClicked: true });
      this.props.showModal({
        component: InvoiceViewer,
        componentProps: {
          closeOnClickOutSide: true,
          closeOnEsc: false,
          showCloseButton: true,
        },
      });
      SegmentIO.clickViewInvoiceEvent(lazyFetch);
      SegmentIO.transactionEngaged({
        activity_type: 'optional_actions',
        ui_action: 'clicked',
        ui_object: 'view_invoice_pdf',
        ui_object_detail: 'button',
        ui_access_point: 'transaction_flow',
      });
    };
    const onClickPrint = async () => {
      await lazyFetchPDF();
      this.setState({ invoiceDownloadViewOrPrintClicked: true });
      try {
        // HTMLIFrameElement has a contentWindow property
        // @ts-ignore
        document.getElementById('hiddenPDFIframe').contentWindow.print();
      } catch (e) {
        onClickView();
      }
      SegmentIO.clickPrint('invoice/invoice_dashboard', lazyFetch);
      SegmentIO.transactionEngaged({
        activity_type: 'optional_actions',
        ui_action: 'clicked',
        ui_object: 'print_invoice',
        ui_object_detail: 'button',
        ui_access_point: 'transaction_flow',
      });
    };
    const fetchPDFSuccessful = fetchPDFStatus === TXN_MAP.STATUS.SUCCESS;
    const sections = [];

    sections.push(
      <InvoiceInfo
        featureFlags={featureFlags}
        invoiceAmount={amount}
        shouldShowInvoiceLineItems={shouldShowInvoiceLineItems}
        invoiceNumber={number}
        gratuityValue={gratuityValue}
        invoiceDueDate={dueDate}
        isPartiallyPaid={isPartiallyPaid}
        currency={currency}
        useRedesign={false}
        token={token}
        fetchPDFStatus={fetchPDFStatus}
        showModal={showModal}
        invoiceLineItems={lineItems}
        invoiceTaxAmount={taxAmount}
        feeAmount={feeAmount}
      />
    );
    if (!!balanceAmount && isPayable && !isFullyPaid) {
      sections.push(
        <PaymentInfo
          amount={totalAmount}
          balanceAmount={balanceAmount}
          currency={currency}
          CFTooltip={true}
          beforePay={true}
        />
      );
    }
    sections.push(
      <Fragment>
        <div className="flex vertical-margin-20 center-align">
          <div className="view-invoice-button-container">
            <ProgressButton
              backgroundColor={colors.white}
              size="mini"
              width="auto"
              onClick={onClickView}
              disabled={!fetchPDFSuccessful && this.state.invoiceDownloadViewOrPrintClicked}
              spinner={
                fetchPDFStatus === TXN_MAP.STATUS.IN_PROGRESS &&
                this.state.invoiceDownloadViewOrPrintClicked
              }
              spinnerWidth={15}
              data-cy="invoice-summary-view-invoice"
            >
              <ViewInvoiceMessage
                // @ts-ignore
                fetchPDFStatus={fetchPDFStatus}
                isFull={shouldShowInvoiceLineItems}
                userClicked={this.state.invoiceDownloadViewOrPrintClicked}
              />
            </ProgressButton>
          </div>
          <div className="print-download-button-container flex">
            <div
              className="button-container flex center-align"
              onClick={onClickDownload}
              onKeyPress={onClickDownload}
              tabIndex={0}
              data-cy="invoice-summary-download"
              aria-label="Download Invoice"
            >
              <Download
                color={
                  fetchPDFSuccessful || !this.state.invoiceDownloadViewOrPrintClicked
                    ? colors.gray
                    : colors.gray04
                }
                width={24}
                height={24}
              />
            </div>
            <div className="buttons-separator" />
            <div
              className="button-container flex center-align"
              onClick={onClickPrint}
              onKeyPress={onClickPrint}
              tabIndex={0}
              data-cy="invoice-summary-print"
            >
              <Print
                color={
                  fetchPDFSuccessful || !this.state.invoiceDownloadViewOrPrintClicked
                    ? colors.gray
                    : colors.gray04
                }
                width={24}
                height={24}
              />
            </div>
          </div>
        </div>

        <style jsx>{`
          .vertical-margin-20 {
            margin-top: 20px;
          }
          .center-align {
            align-items: center;
          }
          .view-invoice-button-container {
            display: flex;
            align-items: center;
            flex-grow: 1;
          }
          .print-download-button-container {
            justify-content: flex-end;
            flex-grow: 2;
          }
          .button-container {
            cursor: pointer;
            position: relative;
            &:hover,
            &:focus {
              background-color: ${colors.gray06};
            }
          }
          .buttons-separator {
            width: 16px;
          }
        `}</style>
      </Fragment>
    );

    let error;
    if (this.state.invoiceDownloadViewOrPrintClicked && fetchPDFStatus === TXN_MAP.STATUS.ERROR) {
      error = (
        <Fragment>
          <div>
            <FormattedMessage
              id="ERROR_TEMPORARILY_UNAVAILABLE_INVOICE_REQUEST"
              defaultMessage="Invoice temporarily unavailable. "
            />
            <FormattedMessage
              id="ERROR_GENERIC_APPRECIATE_PATIENCE"
              defaultMessage="We appreciate your patience. "
            />
            <a className="try-again" onClick={() => this.props.fetchPDFDocument(token)}>
              <FormattedMessage id="TRY_AGAIN" defaultMessage="Try again" />
            </a>
          </div>
          <style jsx>{`
            .try-again {
              color: ${colors.blue01};
            }
          `}</style>
        </Fragment>
      );
    }
    return (
      <PreviewCard title={companyName} image={companyLogoUrl} sections={sections} error={error} />
    );
  }
}

export default connect(() => ({}), { fetchPDFDocument })(InvoicePreviewCard);
